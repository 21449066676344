/*
================
 VARIABLES
================
*/
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.shadow-1 {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

@media only screen and (max-width: 1400px) {
    .mh-single-project-slide-by-side {
        .owl-prev {
            top: 40%;
            left: 0;
        }
        .owl-next {
            top: 44%;
            right: 0;
        }
    }
}
@media only screen and (max-width: 991px) {
    .section-separator {
        padding: 40px 0;
    }
    .mh-home-2 {
        .mh-header-info {
            p {
                padding: 0 0px;
                font-weight: 300;
            }
            .mh-about-tag {
                width: 80%;
            }
        }
    }
    .mh-footer-address {
        .mh-address-footer-item {
            .each-icon {
                width: 60px;
                height: 60px;
                line-height: 60px;
            }
        }
    }
    .mh-xs-mobile-nav {
        z-index: 99;
        .nav-btn {
            .navbar-toggler.active {
                display: none;
            }
        }
        .navbar-nav {
            padding-top: 50px;
            padding-top: 50px;
            padding-right: 20px;
        }
        .navbar-collapse {
            position: fixed;
            top: 0;
            bottom: 0;
            width: 320px;
            left: 0;
            z-index: 990;
            margin-top: 0px;
            padding: 0;
            background-color: #0bceaf;
            transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -o-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -ms-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -moz-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -webkit-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -webkit-transition: all 0.4s ease-out;
            -moz-transition: all 0.4s ease-out;
            -o-transition: all 0.4s ease-out;
            -ms-transition: all 0.4s ease-out;
            transition: all 0.4s ease-out;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            overflow-y: none;
            position: fixed;
            top: 0;
            bottom: 0;
            width: 320px;
            left: 0;
            z-index: 990;
            margin-top: 0px;
            padding: 0;
            background-color: #0bceaf;
            transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -o-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -ms-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -moz-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -webkit-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -webkit-transition: all 0.4s ease-out;
            -moz-transition: all 0.4s ease-out;
            -o-transition: all 0.4s ease-out;
            -ms-transition: all 0.4s ease-out;
            transition: all 0.4s ease-out;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            overflow-y: none;
            .nav {
                margin-top: 75px;
                border-top: 1px solid;
                border-top-color: rgba(255, 255, 255, 0.1);
                padding: 20px;
                li {
                    text-align: center;
                    margin-bottom: 0px;
                    width: 100%;
                    display: block;
                    -webkit-transition: all 0.4s ease-out;
                    -moz-transition: all 0.4s ease-out;
                    -o-transition: all 0.4s ease-out;
                    -ms-transition: all 0.4s ease-out;
                    transition: all 0.4s ease-out;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translate3d(-20px, -10px, 0);
                    a {
                        background-color: transparent;
                        text-transform: uppercase;
                        font-size: 17px;
                        line-height: 26px;
                        &:hover {
                            color: white !important;
                            background: transparent;
                        }
                    }
                    a.active {
                        color: #fff;
                    }
                }
                li.active {
                    color: #fff;
                }
            }
        }
        .navbar-collapse.active {
            transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
            transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
            -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
        }
        .collapse {
            display: block;
        }
        .navbar-fixed-bottom {
            .navbar-collapse {
                max-height: inherit;
            }
        }
        .navbar-fixed-top {
            .navbar-collapse {
                max-height: inherit;
            }
        }
        .navbar-header {
            position: fixed;
            z-index: 999;
            width: 100%;
            margin-top: 0px;
            left: 0;
            .navbar-toggler {
                .icon-bar {
                    background-color: #0bceaf;
                    -webkit-transition: all 0.4s ease-out;
                    -moz-transition: all 0.4s ease-out;
                    -o-transition: all 0.4s ease-out;
                    -ms-transition: all 0.4s ease-out;
                    transition: all 0.4s ease-out;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
            .navbar-toggler.active {
                .icon-bar {
                    background-color: #fff;
                }
            }
        }
        .navbar-toggler {
            .icon {
                transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
                -o-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
                -ms-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
                -moz-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
                -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
                width: 22px;
                height: 2px;
                position: absolute;
                border-radius: 2px;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                &::after {
                    width: 22px;
                    height: 2px;
                    position: absolute;
                    border-radius: 2px;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    content: '';
                    top: 0;
                    right: 0;
                    -webkit-transition: all 0.6s ease-out;
                    -moz-transition: all 0.6s ease-out;
                    -o-transition: all 0.6s ease-out;
                    -ms-transition: all 0.6s ease-out;
                    transition: all 0.6s ease-out;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
                    -o-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
                    -ms-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
                    -moz-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
                    -webkit-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
                }
                &::before {
                    width: 22px;
                    height: 2px;
                    position: absolute;
                    border-radius: 2px;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    content: '';
                    top: 0;
                    right: 0;
                    -webkit-transition: all 0.6s ease-out;
                    -moz-transition: all 0.6s ease-out;
                    -o-transition: all 0.6s ease-out;
                    -ms-transition: all 0.6s ease-out;
                    transition: all 0.6s ease-out;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
                    -o-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
                    -ms-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
                    -moz-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
                    -webkit-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
                }
            }
            &:focus {
                outline: 0 !important;
            }
            padding: 15px;
            margin-right: 15px;
            margin-left: 15px;
            margin-top: 30px;
            z-index: 1000;
            -webkit-transition: all 0.4s ease-out;
            -moz-transition: all 0.4s ease-out;
            -o-transition: all 0.4s ease-out;
            -ms-transition: all 0.4s ease-out;
            transition: all 0.4s ease-out;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: block;
        }
        .navbar-toggler.active {
            .icon {
                &::after {
                    transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
                    -o-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
                    -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
                    -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
                    -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
                    background-color: #fff;
                }
                &::before {
                    transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
                    -o-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
                    -ms-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
                    -moz-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
                    -webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
                    background-color: #fff;
                }
                background-color: transparent;
            }
        }
        .strict {
            background-color: rgba(0, 0, 0, 0.9);
            padding-bottom: 65px;
        }
        .overlay {
            position: fixed;
            display: none;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 3;
        }
        .overlay.active {
            display: block;
        }
        .collapse.in {
            background-color: rgba(0, 0, 0, 0.9);
        }
    }
    .mh-home {
        .mh-header-info {
            padding: 0;
            h2 {
                font-size: 42px;
                font-weight: 600;
                line-height: 54px;
            }
            h4 {
                font-size: 20px;
                line-height: 24px;
            }
            .mh-promo {
                margin-bottom: 20px;
            }
        }
    }
    .mh-header {
        padding: 10px 0;
    }
    .home-padding {
        padding: 100px 0;
    }
    .mh-about {
        .mh-about-inner {
            padding-left: 5px;
        }
    }
    .mh-nav {
        padding: 0;
    }
    .mh-professional-skills {
        padding-left: 0;
    }
    .mh-skills {
        .mh-professional-skill {
            padding-right: 0;
        }
    }
    .nav-strict {
        .navbar-toggler {
            margin-top: 0px;
        }
    }
    .mh-footer-3 {
        .mh-footer-address {
            .mh-address-footer-item {
                .each-info {
                    padding-left: 20px;
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .sm-column-reverse {
        display: flex;
        flex-direction: column-reverse;
    }
    .mh-featured-project-content {
        margin-top: 40px;
        text-align: center;
        overflow: hidden;
    }
    .mh-home {
        .mh-header-info {
            padding: 0;
            text-align: center;
            margin-top: 40px;
        }
    }
    .mh-featured-project {
        .mh-featured-item {
            .mh-featured-project-content {
                p {
                    text-align: center;
                }
            }
        }
    }
    .mh-experince {
        h3 {
            margin-bottom: 30px;
        }
    }
    .mh-work {
        h3 {
            margin-bottom: 30px;
            margin-top: 30px;
        }
        padding-left: 0;
    }
    .mh-education {
        padding-right: 0;
    }
    .mh-skills {
        .mh-professional-skill {
            h3 {
                margin-bottom: 30px;
            }
        }
    }
    .mh-professional-skills {
        h3 {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
    .mh-about {
        .mh-about-inner {
            margin-top: 40px;
        }
    }
    .mh-footer-address {
        .mh-address-footer-item {
            .each-icon {
                width: 70px;
                height: 70px;
                line-height: 70px;
                margin: auto;
            }
        }
    }
    .mh-home-2 {
        .mh-header-info {
            ul.mh-home-contact {
                li {
                    display: inline-block;
                    margin: 10px 15px;
                    width: 40%;
                    text-align: left;
                }
            }
            .mh-about-tag {
                width: 100%;
            }
        }
    }
    .mh-testimonial {
        .mh-client-item {
            padding: 30px 10px;
        }
    }
    .single-comment {
        margin: 0 auto;
        text-align: center;
        display: block;
        margin-top: 30px;
        img {
            margin-bottom: 20px;
        }
    }    
}
@media only screen and (max-width: 575px) {
    .xs-column-reverse {
        display: flex;
        flex-direction: column-reverse;
    }
    .mh-header {
        padding: 0;
    }
    .home-padding {
        padding: 100px 0;
    }
    .mh-home {
        .hero-img {
            margin-bottom: 30px;
        }
        .mh-header-info {
            padding: 0;
            text-align: center;
            margin-top: 40px;
            .mh-promo {
                margin-bottom: 20px;
            }
        }
    }
    .mh-about {
        .mh-about-inner {
            padding-left: 5px;
            text-align: center;
            margin: 30px 15px;
        }
    }
    .mh-skills {
        .mh-professional-skill {
            padding-right: 0;
            padding: 0 15px;
            margin-bottom: 50px;
        }
    }
    .mh-experince {
        h3 {
            margin-bottom: 30px;
            text-align: center;
        }
    }
    .mh-education {
        margin-bottom: 30px;
        padding-right: 0;
    }
    .mh-work {
        padding-left: 0;
        margin-bottom: 30px;
    }
    .portfolio-nav {
        ul {
            li {
                margin-bottom: 20px;
            }
        }
    }
    .section-separator {
        padding: 30px 0;
    }
    .mh-quotes {
        .each-quotes {
            padding: 30px;
        }
    }
    .mh-map {
        padding: 0px;
        margin-top: 40px;
    }
    .mh-home-2 {
        .mh-header-info {
            ul.mh-home-contact {
                li {
                    width: 100%;
                    margin: 6px 10px;
                }
            }
            text-align: center;
            padding-top: 50px;
        }
    }
    .mh-footer-3 {
        .mh-footer-address {
            .mh-address-footer-item {
                display: block;
                padding: 20px 0px;
                .each-info {
                    padding-left: 20px;
                    text-align: center;
                    padding-left: 0;
                    text-align: center;
                }
            }
        }
    }
    .xs-no-padding {
        padding: 0;
    }
    .mh-portfolio-modal-inner {
        margin-right: 0;
    }
    .mh-portfolio-modal {
        padding: 10px;
    }
    .mh-portfolio-modal-img {
        margin-top: 50px;
    }
    .mh-service {
        .mh-service-item {
            margin-bottom: 20px;
        }
    }
    .social-icon {
        display: block;
        margin: 0 auto;
        float: none;
        text-align: center;
        li {
            list-style: none;
            float: none;
            text-align: center;
            display: inline-block;
            margin-bottom: 0px;
            margin-right: 4px;
            margin-left: 4px;
        }
    }
    .text-left.text-xs-center {
        text-align: center !important;
    }    
}
@media only screen and (max-width: 420px) {
    .mh-home .img-border {
        height: 280px;
        width: 280px;
    }

    .home-padding {
        padding: 60px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
    .hero-img .img-border {
        height: 350px;
        width: 350px;
    }
}
/*# sourceMappingURL=responsive.css.map */
