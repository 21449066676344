@import 'custom.scss';
@import 'animation.scss';
@import 'styles.scss';
@import 'responsive.scss';
@import 'buttons.scss';
@import 'loader.scss';

html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    font-style: none;
    line-height: 24px;
    font-weight: 500;
    overflow-x: hidden !important;
    text-align: left;
    position: relative;
    color: #999999;
    opacity: 0.9;
    -moz-transition: 0.3s;
	-o-transition: 0.3s;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
