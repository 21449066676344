/*
    ==============
       ABOUT
    ==============
*/
.mh-about {
	.mh-about-inner {
		padding-left: 50px;
		h2 {
			margin-bottom: 20px;
			margin-top: 20px;
		}
		p {
			margin-bottom: 30px;
			text-align: justify;
		}
		.btn {
			margin-top: 20px;
			.fa {
				margin-left: 10px;
			}
			.svg-inline--fa {
				margin-left: 10px;
			}
		}
	}
}
.mh-about-tag {
	word-break: break-all;
	margin-bottom: 10px;
	margin-top: 10px;
	ul {
		li {
			margin: 0px 6px;
			border-radius: 4px;
			margin-bottom: 10px;
			padding: 4px 0px;
			display: inline-block;
			span {
				border: 1px solid #0bceaf;
				padding: 4px 10px;
				border-radius: 4px;
				&:hover {
					background-color: transparent !important;
				}
			}
		}
	}
}