/*
    ==============
      FOOTER ONE
    ==============
*/
.mh-footer ::placeholder {
    opacity: 0.9;
}

/*
==================
    FOOTER 3
=================
*/
.mh-footer-3 {
	.mh-footer-address {
		.mh-address-footer-item {
			padding: 10px 30px;
			text-align: center;
			margin-bottom: 15px;
			h4 {
				margin-bottom: 0;
				margin-top: 10px;
			}
			.each-info {
				padding-left: 40px;
				text-align: left;
			}
		}
	}
}

/*
    ============
    Footer Address
    ==============
*/
.mh-footer-address {
	margin-bottom: 60px;
	.mh-address-footer-item {
		padding: 30px;
		text-align: center;
		margin-bottom: 30px;
		border-radius: 10px;
		h4 {
			margin-bottom: 20px;
		}
		address {
			margin-bottom: 0;
		}
		.each-icon {
			text-align: center;
			width: 50px;
			height: 50px;
			border: 1px solid;
			border-radius: 50%;
			line-height: 50px;
			font-size: 20px;
			border-color: #0bceaf;
			align-self: center;
		}
	}
}