/*---------------------------------------------------------------------*/
/* Loader
/*---------------------------------------------------------------------*/
.section-loader {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	z-index: 9999999;
	.loader {
		position: relative;
		width: 200px !important;
		height: 200px !important;
		-webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
		transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
		div {
			box-sizing: content-box;
			position: absolute;
			border-width: 4px;
			border-style: solid;
			opacity: 1;
			border-radius: 50%;
			-webkit-animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
			animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
			&:nth-child(1) {
				border-color: #e91e63;
			}
			&:nth-child(2) {
				border-color: #0dbda1;
				-webkit-animation-delay: -0.5s;
				animation-delay: -0.5s;
			}
		}
	}
}


@keyframes loader {
    0% {
        top: 94px;
        left: 94px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 57px;
        left: 57px;
        width: 74px;
        height: 74px;
        opacity: 0;
    }
}

@-webkit-keyframes loader {
    0% {
        top: 94px;
        left: 94px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 57px;
        left: 57px;
        width: 74px;
        height: 74px;
        opacity: 0;
    }
}