/*
    ==============
       QUOTES
    ==============
*/
.mh-quotes {
	.each-quotes {
		padding-top: 30px;
		padding-bottom: 30px;
		p {
			margin-top: 20px;
		}
		.fa {
			background-color: #fff;
			border-radius: 50%;
			color: black;
			height: 40px;
			width: 40px;
			margin-top: 20px;
			line-height: 40px;
			text-align: center;
		}
		.svg-inline--fa {
			background-color: #fff;
			border-radius: 50%;
			color: black;
			height: 40px;
			width: 40px;
			margin-top: 20px;
			line-height: 40px;
			text-align: center;
		}
	}
}