/*
================
 VARIABLES
================
*/
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.shadow-1 {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

/**
 * -------------------
 *  Defult CSS
 * -------------------
 */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'roboto', sans-serif;
}

p {
    opacity: 0.9;
}

.nav li a {
    text-decoration: none !important;
    display: inline-block;
    cursor: pointer;
    padding: 0px;
    background-color: transparent;
    -moz-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;

    &:hover {
        background-color: transparent;
    }

    &:focus {
        background-color: transparent;
        outline: 0 !important;
    }
}

a {
    text-decoration: none !important;
    cursor: pointer;
    padding: 0px;
    background-color: transparent;
    -moz-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    color: #000;
    opacity: 0.9;

    &:hover {
        background-color: transparent;
    }
    &:focus {
        background-color: transparent;
        outline: 0 !important;
    }
}

/**
 * ---------------------
 *  Defult Halping Class
 * ---------------------
 */

.display-flex {
    display: flex;
    flex: 1;
}

.section-title {
    text-align: center;
    h3 {
        font-size: 30px;
        line-height: 40px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 60px;
    }
    h2 {
		margin-bottom: 60px;
	}
}

/**
 * -----------------
 * Social Icon Style
 * -----------------
 */
 .social-icon {
	display: inline-block;
	li {
		list-style: none;
		float: left;
		margin-bottom: 0px;
		margin-right: 8px;
		a {
			text-align: center;
			width: 45px;
			height: 45px;
			padding: 10px;
			border-radius: 50%;
		}
		&:last-child {
			a {
				margin-right: 0px;
			}
		}
	}
}


/**
 * ------------------
 * list
 * ------------------
 */
ul {
    margin: 0;
    padding: 0;
}
ul li {
    list-style-type: none;
}

/**
 * ---------------------------------
 *  Section Background Size Property
 * ---------------------------------
 */
.dark-bg {
	background-color: #202026;
}
.section-separator {
	padding: 60px 0;
}

.image-bg {
	background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	margin: 0 auto;
	background-position: center center;
}
.home-2-img {
	background-image: url(../images/home-bg-img.jpg);
}
.featured-img-one {
	background-image: url(../images/home-bg-img.jpg);
}
.map-image {
	background-image: url(../images/map-color-overlay.png);
}
.mt-30 {
	margin-top: 30px;
}

button {
	outline: 0;
	&:hover {
		outline: 0;
	}
	&:focus {
		outline: 0;
	}
}

.round-image {
	border-radius: 50%;
}

/**
 * -----------------
 * General CSS
 * -----------------
 */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

.dark-version {
	opacity: 0.9;
	.section-loader {
		background: #100e17;
	}
	&::placeholder,
    a,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    address,
    span,
    div, 
    ul li {
		color: #fff;
	}
    
	.nav-strict {
		background-color: #202026;
	}
	.img-color-overlay {
		background-color: rgba(0, 0, 0, 0.85);
	}

	.navbar-toggler {
		.icon,
		.icon::after,
		.icon::before {
			background-color: #fff;
		}
	}
	.mh-header {
		.navbar-nav {
			li {
				a.active {
					border-color: #fff;
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
	}

	.mh-home {
		.hero-img {
			img {
				border: 20px solid rgba(0, 0, 0, 0.4);
			}
		}
		.img-border {
			border: 20px solid rgba(0, 0, 0, 0.9);
		}
	}

	.candidatos {
		.parcial {
			.progressBar {
				background: rgba(199, 198, 198, 0.6);
			}
		}
	}

	.mh-progress {
		path {
			&:nth-child(1) {
				stroke: rgba(255, 255, 255, 0.5);
			}
		}
	}
}

.dark-version.black-bg {
	background-color: #100e17;
}

/*
    ==============
       Navigation
    ==============
*/
.mh-nav {
	width: 100%;
}
.mh-header {
	padding: 20px 0;
	-moz-transition: 0.3s;
	-o-transition: 0.3s;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	.navbar-nav {
		li {
			margin: 0 20px;
			.nav-link {
				border-bottom: 1px solid transparent;
				font-weight: bold;
				font-family: 'roboto', sans-serif;
				font-size: 18px;
				opacity: 1;
				line-height: 30px;
				background: transparent;
				border: none;
				color: #fff;
				padding-left: 0;
				padding-right: 0;
				&:hover {
					color: #0bceaf;
				}
			}
		}
	}
}
.mh-header.nav-strict {
	padding: 15px 0;
	-moz-transition: 0.3s;
	-o-transition: 0.3s;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.home-padding {
	padding: 180px 0;
}


/*
====================
    Subpage
====================
*/
.mh-page-title {
	padding-top: 100px;
	h2 {
		font-size: 40px;
		line-height: 46px;
		text-transform: uppercase;
		font-weight: bold;
		margin-bottom: 10px;
	}
}
.nav-strict {
	z-index: 99;
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	-moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.mh-fixed-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
}


/*
===============
    Mobile Nav
==============
*/

.new {
	z-index: 99;
	.nav-btn {
		.navbar-toggler.active {
			display: none;
		}
	}
	.navbar-collapse {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 320px;
		left: 0;
		z-index: 990;
		margin-top: 0px;
		padding: 0;
		background-color: #0bceaf;
		transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-o-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-ms-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-moz-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-webkit-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-webkit-transition: all 0.4s ease-out;
		-moz-transition: all 0.4s ease-out;
		-o-transition: all 0.4s ease-out;
		-ms-transition: all 0.4s ease-out;
		transition: all 0.4s ease-out;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		overflow-y: none;
		position: fixed;
		top: 0;
		bottom: 0;
		width: 320px;
		left: 0;
		z-index: 990;
		margin-top: 0px;
		padding: 0;
		background-color: #0bceaf;
		transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-o-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-ms-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-moz-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-webkit-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-webkit-transition: all 0.4s ease-out;
		-moz-transition: all 0.4s ease-out;
		-o-transition: all 0.4s ease-out;
		-ms-transition: all 0.4s ease-out;
		transition: all 0.4s ease-out;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		overflow-y: none;
		.nav {
			margin-top: 75px;
			border-top: 1px solid;
			border-top-color: rgba(255, 255, 255, 0.1);
			padding: 20px;
			li {
				text-align: center;
				margin-bottom: 0px;
				width: 100%;
				display: block;
				-webkit-transition: all 0.4s ease-out;
				-moz-transition: all 0.4s ease-out;
				-o-transition: all 0.4s ease-out;
				-ms-transition: all 0.4s ease-out;
				transition: all 0.4s ease-out;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transform: translate3d(-20px, -10px, 0);
				a {
					background-color: transparent;
					text-transform: uppercase;
					font-size: 17px;
					line-height: 26px;
					&:hover {
						color: white !important;
						background: transparent;
					}
				}
				a.active {
					color: #fff;
				}
			}
			li.active {
				color: #fff;
			}
		}
	}
	.navbar-collapse.active {
		transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
		transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-o-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-ms-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-moz-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
		-webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(0deg) scale(1);
	}
	.collapse {
		display: block;
	}
	.navbar-fixed-bottom {
		.navbar-collapse {
			max-height: inherit;
		}
	}
	.navbar-fixed-top {
		.navbar-collapse {
			max-height: inherit;
		}
	}
	.navbar-header {
		position: fixed;
		z-index: 999;
		width: 100%;
		margin-top: 0px;
		left: 0;
		.navbar-toggler {
			.icon-bar {
				background-color: #0bceaf;
				-webkit-transition: all 0.4s ease-out;
				-moz-transition: all 0.4s ease-out;
				-o-transition: all 0.4s ease-out;
				-ms-transition: all 0.4s ease-out;
				transition: all 0.4s ease-out;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		.navbar-toggler.active {
			.icon-bar {
				background-color: #fff;
			}
		}
	}
	.navbar-toggler {
		.icon {
			transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
			-o-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
			-ms-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
			-moz-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
			-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg) scale(1);
			width: 22px;
			height: 2px;
			position: absolute;
			background-color: #fff;
			border-radius: 2px;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			&::after {
				width: 22px;
				height: 2px;
				position: absolute;
				background-color: #fff;
				border-radius: 2px;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				content: '';
				top: 0;
				right: 0;
				-webkit-transition: all 0.6s ease-out;
				-moz-transition: all 0.6s ease-out;
				-o-transition: all 0.6s ease-out;
				-ms-transition: all 0.6s ease-out;
				transition: all 0.6s ease-out;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
				-o-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
				-ms-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
				-moz-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
				-webkit-transform: translateX(0) translateY(6px) translateZ(0) rotate(0deg) scale(1);
			}
			&::before {
				width: 22px;
				height: 2px;
				position: absolute;
				background-color: #fff;
				border-radius: 2px;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				content: '';
				top: 0;
				right: 0;
				-webkit-transition: all 0.6s ease-out;
				-moz-transition: all 0.6s ease-out;
				-o-transition: all 0.6s ease-out;
				-ms-transition: all 0.6s ease-out;
				transition: all 0.6s ease-out;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
				-o-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
				-ms-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
				-moz-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
				-webkit-transform: translateX(0) translateY(-6px) translateZ(0) rotate(0deg) scale(1);
			}
		}
		&:focus {
			outline: 0 !important;
		}
		padding: 15px;
		margin-right: 15px;
		margin-left: 20%;
		margin-top: 15px;
		z-index: 1000;
		-webkit-transition: all 0.4s ease-out;
		-moz-transition: all 0.4s ease-out;
		-o-transition: all 0.4s ease-out;
		-ms-transition: all 0.4s ease-out;
		transition: all 0.4s ease-out;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		display: block;
	}
	.navbar-toggler.active {
		.icon {
			&::after {
				transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
				-o-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
				-ms-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
				-moz-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
				-webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(138deg) scale(1);
				background-color: #fff;
			}
			&::before {
				transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
				-o-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
				-ms-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
				-moz-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
				-webkit-transform: translateX(0) translateY(0) translateZ(0) rotate(-135deg) scale(1);
				background-color: #fff;
			}
			background-color: transparent;
		}
	}
	.strict {
		background-color: rgba(0, 0, 0, 0.9);
		padding-bottom: 65px;
	}
	.overlay {
		position: fixed;
		display: none;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 3;
	}
	.overlay.active {
		display: block;
	}
	.collapse.in {
		background-color: rgba(0, 0, 0, 0.9);
	}
}
