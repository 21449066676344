/*
    ==============
       SKILLS
    ==============
*/
.mh-skills {
	.mh-professional-skill {
		padding-right: 60px;
		h3 {
			font-size: 30px;
			line-height: 40px;
			text-align: center;
			font-weight: bold;
			margin-bottom: 60px;
		}
	}
}
.mh-professional-skills {
	padding-left: 20%;
	h3 {
		font-size: 30px;
		line-height: 40px;
		text-align: center;
		font-weight: bold;
		margin-bottom: 60px;
	}
}
.mh-professional-progress {
	li {
		display: inline-block;
		margin: 0 auto;
		float: none;
		width: 48%;
		margin-bottom: 30px;
		text-align: center;
	}
}
.mh-progress {
	margin-bottom: 10px;
	font: 900 1.1428571429em/1 Cinzel, cursive;
	.progressbar-text {
		color: inherit !important;
	}
	path {
		&:nth-child(1) {
			stroke: rgba(0, 0, 0, 0.7);
		}
		&:nth-child(2) {
			stroke: #0bceaf;
		}
	}
}
.progress.progress-line {
	height: 10px;
	margin-right: 60px;
	.progressbar-text {
		position: absolute;
		top: 50%;
		left: 100%;
		width: 60px;
		-webkit-transform: translateY(-35%);
		-ms-transform: translateY(-35%);
		transform: translateY(-35%);
		text-align: center;
	}
}
.mh-progress.mh-progress-circle {
	display: inline-block;
	width: 100px;
	height: 100px;
}


@-webkit-keyframes animationProgress {
	from {
		width: 0;
	}
}
@keyframes animationProgress {
	from {
		width: 0;
	}
}
.candidatos {
	position: relative;
	margin-bottom: 15px;
	.parcial {
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		.info {
			position: relative;
			.nome {
				position: absolute;
				top: 0;
				left: 0;
				font-size: 15px;
				font-weight: 600;
				opacity: 0.9;
			}
			.percentagem-num {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 14px;
				font-weight: normal;
			}
		}
		.progressBar {
			position: relative;
			width: 100%;
			height: 7px;
			margin: 30px 0 2px;
			border-radius: 10px;
			background: rgba(0, 0, 0, 0.5);
		}
		.percentagem {
			position: absolute;
			top: 0;
			left: 0;
			height: 7px;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			background: #0bceaf;
			transition: 3s all;
			-webkit-transition: 3s all;
			animation-duration: 3s;
			-webkit-animation-duration: 3s;
			animation-name: animationProgress;
			-webkit-animation-name: animationProgress;
		}
		.partidas {
			font-size: 10px;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
}
.percentual {
	animation-name: animationProgress;
}