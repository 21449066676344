/*
    ==============
      EXPERIENCES
    ==============
*/
.mh-experince {
	h3 {
		font-size: 30px;
		line-height: 40px;
		font-weight: bold;
		margin-bottom: 60px;
	}
	.mh-education-details {
		.mh-education-item {
			margin-bottom: 30px;
			padding: 26px 30px;
			-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
			-moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
			border-radius: 10px;
			&:last-child {
				margin-bottom: 0;
			}
			h4 {
				font-size: 20px;
				line-height: 30px;
				font-weight: 600;
				a {
					font-style: italic;
					margin-left: 4px;
					color: #0bceaf;
				}
			}
			.mh-eduyear {
				margin: 10px 0;
				color: #0bceaf;
			}
			p {
				font-size: 14px;
				line-height: 23px;
				margin-bottom: 0;
			}
		}
	}
}


/*
    ==============
      EDUCATION
    ==============
*/
.mh-education {
	padding-right: 30px;
}
.mh-work {
	padding-left: 30px;
	h3 {
		font-size: 30px;
		line-height: 40px;
		font-weight: bold;
		margin-bottom: 60px;
	}
	.mh-experience-details {
		.mh-work-item {
			margin-bottom: 30px;
			padding: 20px 30px;
			-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
			-moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
			border-radius: 10px;
			&:last-child {
				margin-bottom: 0;
			}
			h4 {
				font-size: 20px;
				line-height: 30px;
				font-weight: 600;
				a {
					color: #0bceaf;
				}
			}
            .work-department {
                font-size: 1rem;
                font-weight: bold;
                font-family: 'roboto', sans-serif;
                line-height: 1.2;
                margin-top: 0;
                margin-bottom: 0.5rem;
            }
			.mh-eduyear {
				margin: 10px 0;
				color: #0bceaf;
			}
			span {
				font-size: 12px;
				line-height: 22px;
			}
			.work-responsibility {
				li {
					font-size: 14px;
					line-height: 23px;
					margin: 2px 0;
					.fa {
						font-size: 10px;
						line-height: 23px;
						margin-right: 10px;
					}
					.svg-inline--fa {
						font-size: 10px;
						line-height: 23px;
						margin-right: 10px;
					}
				}
			}
		}
	}
}