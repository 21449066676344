/*
    ==============
       HOME
    ==============
*/

.mh-home {
	.mh-header-info {
		padding-top: 100px;
		.mh-promo {
			margin-bottom: 30px;
			span {
				background-color: #00856E;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 1px;
				font-weight: bold;
				padding: 10px 30px;
				border-top-left-radius: 20px;
				border-top-right-radius: 20px;
				border-bottom-right-radius: 20px;
				border-bottom-left-radius: 0px;
			}
		}
		h2 {
			font-size: 60px;
			font-weight: 600;
			line-height: 65px;
			margin-top: 10px;
			margin-bottom: 10px;
		}
		h4 {
			font-size: 26px;
			line-height: 34px;
			margin-bottom: 30px;
		}
		ul {
			margin-top: 20px;
			li {
				margin: 10px 0;
				.fa {
					margin-right: 15px;
					display: inline-block;
				}
				.svg-inline--fa {
					margin-right: 15px;
					display: inline-block;
				}
				address {
					display: inline-block;
				}
				&:hover {
					.fa {
						color: #0bceaf;
					}
					.svg-inline--fa {
						color: #0bceaf;
					}
				}
			}
		}
		.social-icon {
			margin-top: 0;
			li {
				.fa {
					font-size: 18px;
					line-height: 30px;
					margin-right: 10px;
					-moz-transition: 0.3s;
					-o-transition: 0.3s;
					-webkit-transition: 0.3s;
					transition: 0.3s;
					border-radius: 3px;
					&:hover {
						-moz-transition: 0.3s;
						-o-transition: 0.3s;
						-webkit-transition: 0.3s;
						transition: 0.3s;
					}
				}
				.fab {
					font-size: 18px;
					line-height: 30px;
					margin-right: 10px;
					-moz-transition: 0.3s;
					-o-transition: 0.3s;
					-webkit-transition: 0.3s;
					transition: 0.3s;
					border-radius: 3px;
					&:hover {
						-moz-transition: 0.3s;
						-o-transition: 0.3s;
						-webkit-transition: 0.3s;
						transition: 0.3s;
					}
				}
				.svg-inline--fa {
					font-size: 18px;
					line-height: 30px;
					margin-right: 10px;
					-moz-transition: 0.3s;
					-o-transition: 0.3s;
					-webkit-transition: 0.3s;
					transition: 0.3s;
					border-radius: 3px;
					&:hover {
						-moz-transition: 0.3s;
						-o-transition: 0.3s;
						-webkit-transition: 0.3s;
						transition: 0.3s;
					}
				}
				a {
					font-size: 25px;
					text-align: center;
					width: 30px;
					height: 30px;
					padding: 0;
					margin-right: 0;
					border-radius: 50%;
				}
			}
		}
	}
	.hero-img {
		margin: 0 auto;
		float: none;
		margin-top: 30px;
		text-align: center;
		img {
			margin: 0 auto;
			float: none;
			text-align: center;
			border: 20px solid rgba(0, 0, 0, 0.17);
			border-radius: 50%;
		}
	}
	.img-border {
		height: 400px;
		width: 400px;
		border: 20px solid rgba(0, 0, 0, 0.07);
		margin: 0 auto;
		border-radius: 50%;
	}

    .vertical-middle-content {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        flex-direction: row;
        -webkit-flex-direction: row;
    }
}