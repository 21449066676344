/*
====================
   PORTFOLIO
===================
*/
.grid-item {
	margin-bottom: 20px;
}
.mh-featured-project {
	.mh-featured-item {
		margin: 30px 0;
		.mh-featured-project-content {
			.project-category {
				font-size: 14px;
				color: #0bceaf;
				margin-top: 0;
				margin-bottom: 0;
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: 1px;
			}
			h2 {
				letter-spacing: 2px;
				margin-top: 12px;
				margin-bottom: 12px;
				text-transform: uppercase;
			}
			span {
				text-transform: uppercase;
			}
			p {
				text-align: left;
				margin-top: 10px;
				margin-bottom: 20px;
			}
			.mh-testimonial {
				blockquote {
					padding: 0;
					margin: 0;
					margin-top: 30px;
					q {
						font-style: italic;
						&::before {
							content: open-quote;
						}
						&::after {
							content: close-quote;
						}
					}
					cite {
						display: block;
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						margin-top: 10px;
					}
				}
			}
		}
	}
}
.mh-single-project-slide {
	.owl-controls {
		.owl-nav {
			display: none !important;
		}
		.owl-dots {
			padding-left: 15px;
			margin-top: 30px;
			text-align: center;
			.owl-dot {
				height: 15px;
				width: 15px;
				border: 1px solid #0bceaf;
				display: inline-block;
				border-radius: 50%;
				margin-right: 10px;
			}
			.owl-dot.active {
				background-color: #0bceaf;
			}
		}
	}
}

.mh-featured-project-img {
	img {
		width: inherit !important;
		margin: 0 auto;
		border-radius: 10px;
	}
}

.mh-single-project-slide-by-side {
    margin: 0 auto;
	.owl-next {
		position: absolute;
		border-radius: 50%;
		top: 48%;
		height: 50px;
		width: 50px;
		right: -10%;
		.fa {
			font-size: 40px;
			margin-left: 15px;
		}
		.svg-inline--fa {
			font-size: 40px;
			margin-left: 15px;
		}
	}
	.owl-prev {
		position: absolute;
		border-radius: 50%;
		top: 48%;
		left: -10%;
		height: 50px;
		width: 50px;
		.fa {
			font-size: 40px;
			margin-left: 10px;
		}
		.svg-inline--fa {
			font-size: 40px;
			margin-left: 10px;
		}
	}
}