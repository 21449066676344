/**
 * ---------------------
 *   Defult Button Style
 * ---------------------
 */
 .btn {
	-moz-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	-webkit-transition: color 0.3s ease;
	transition: color 0.3s ease;
	outline: 0;
	padding: 8px 30px;
	font-weight: bold;
	border-radius: 20px;
	&:last-child {
		margin-right: 0px !important;
	}
	.icon {
		position: relative;
		top: 2px;
		img {
			position: relative;
			top: -3px;
			width: 14px;
		}
	}
    &:active.focus,
	&:active:focus,
    &.active.focus,
    &.active:focus,
    &:focus,
    &.focus {
			outline: thin dotted;
			outline: 0px auto -webkit-focus-ring-color !important;
			outline-offset: -2px;
    }

    &.btn-fill {
        background-color: #00856E;
        color: #fff;
    }

    &.left-icon, &.right-icon {
        .icon {
            margin-right: 15px;
        }
    }

    &.btn-border {
        background-color: transparent;
    }

    &.white-btn-border {
        background-color: transparent;
        border-color: #fff;
    }

    &.btn-round {
        padding: 15px;
        border-radius: 50%;
        width: 65px;
        height: 65px;
        font-size: 24px;
        text-align: center;
        .icon {
            top: 7px;
        }
    }

    outline: 0;
	&:hover,
	&:focus {
		outline: 0;
	}
}

.btn-text-link {
	padding: 50px 0 0 0;
	text-align: center;
	a {
		margin-left: 10px;
	}
}