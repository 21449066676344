/*
    ==============
       SERVICES
    ==============
*/
.mh-service {
	.mh-service-item {
		padding: 20px;
		overflow: hidden;
		height: 100%;
		border-radius: 30px;
		.fa {
			font-size: 30px;
			margin-bottom: 20px;
			margin-top: 16px;
		}
		.svg-inline--fa {
			font-size: 30px;
			margin-bottom: 20px;
			margin-top: 16px;
		}
		.fa.purple-color {
			color: #9774fa;
		}
		.svg-inline--fa.purple-color {
			color: #9774fa;
		}
		.fa.main-color {
			color: #0bceaf;
		}
		.svg-inline--fa.main-color {
			color: #0bceaf;
		}
		.fa.iron-color {
			color: #ed7256;
		}
		.svg-inline--fa.iron-color {
			color: #ed7256;
		}
		.fa.sky-color {
			color: #2796e2;
		}
		.svg-inline--fa.sky-color {
			color: #2796e2;
		}
		h3 {
			margin-bottom: 18px;
			font-size: 25px;
			line-height: 34px;
		}
		a {
			color: #0bceaf;
			.fa {
				font-size: 16px;
				line-height: 27px;
				margin-left: 6px;
			}
			.svg-inline--fa {
				font-size: 16px;
				line-height: 27px;
				margin-left: 6px;
			}
		}
	}
}