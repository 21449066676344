.aboutme {
    .infos {
        align-self: center;
    }
    .aboutme-image-container {
        display: flex;
        align-items: center;
    }
}

.navbar-collapse {
    justify-content: center;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #100e17;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #100e17;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #363641;
}
